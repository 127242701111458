import fetch from 'node-fetch';
import { Distribution } from '../../types/distribution';

const getDistribution = async (token: string, distributionName: string, pending: boolean): Promise<Distribution | undefined> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}?pending=${pending}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json '
        }
    });

    if (response.status === 404) {
        return undefined;
    }

    if (!response.ok) {
        throw new Error(`Failed to get Distribution ${distributionName}, got status code ${response.status}`);
    }

    return response.json();
};

export default getDistribution;
