import React from 'react';
import './App.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { Container, Row } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import DistributionsTable from './distributions/table/DistributionsTable';
import DistributionFormManager from './distributions/form/DistributionFormManager';
import OpenApiDocument from './openApiDocument/OpenApiDocument';
import { AuthenticationProvider } from './authentication/Authentication';
import { DistributionsListProvider } from './distributions/table/DistributionsListProvider';
import TopNavigation from './navigation/TopNavigation';

const App = () => {
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_DOMAIN as string}
            clientId={process.env.REACT_APP_CLIENT_ID as string}
            audience={process.env.REACT_APP_AUDIENCE}
            scope={process.env.REACT_APP_SCOPES}
            redirectUri={window.location.origin}
            cacheLocation="localstorage"
        >
            <AuthenticationProvider>
                <DistributionsListProvider>
                    <Container fluid>
                        <Row>
                            <TopNavigation />
                            <Navigation />
                            <Switch>
                                <Route path="/" exact component={DistributionsTable} />
                                <Route
                                    path="/distributions/page/:page"
                                    exact
                                    render={() => {
                                        return <DistributionFormManager key="new" />;
                                    }}
                                />
                                <Route path="/distributions/:distributionName/page/:page" exact component={DistributionFormManager} />
                                <Route path="/oad" exact component={OpenApiDocument} />
                            </Switch>
                        </Row>
                    </Container>
                </DistributionsListProvider>
            </AuthenticationProvider>
        </Auth0Provider>
    );
};

export default App;
