import fetch from 'node-fetch';

const getDistributionNames = async (token: string): Promise<string[]> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json '
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to get distributions, got status ${response.status}`);
    }

    return response.json();
};

export default getDistributionNames;
