import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import putPermission from '../../../util/api/putPermission';
import { useAuthentication } from '../../../authentication/Authentication';
import LoadingButton from '../../../util/LoadingButton';
import Error from '../../../util/Error';
import DisplayMessages from '../../../util/DisplayMessages';

const createEmptyPermission = (distributionName) => {
    return {
        userId: '',
        distributionName,
        permissions: []
    };
};

const AddPermission = ({ distributionName, onAdd, existingPermissions }) => {
    const [userPermission, setUserPermission] = useState(createEmptyPermission(distributionName));
    const [errors, setErrors] = useState([]);

    const [messages, setMessages] = useState([]);

    const { token } = useAuthentication();

    const clearInfo = () => {
        setMessages([]);
        setErrors([]);
    };

    const updateUserId = (event) => {
        clearInfo();

        setUserPermission({
            ...userPermission,
            userId: event.target.value
        });
    };

    const updatePermissions = (event) => {
        clearInfo();

        const value = event.target.value;
        if (value === '') {
            setUserPermission({
                ...userPermission,
                permissions: []
            });
        }

        if (value === 'read') {
            setUserPermission({
                ...userPermission,
                permissions: ['read']
            });
        }

        if (value === 'write') {
            setUserPermission({
                ...userPermission,
                permissions: ['read', 'write']
            });
        }
    };

    const getSelectValue = () => {
        if (userPermission.permissions.length === 0) {
            return '';
        }

        return userPermission.permissions.includes('write') ? 'write' : 'read';
    };

    const validatePermissions = () => {
        setErrors([]);

        const validationErrors = [];
        if (userPermission.userId === '') {
            validationErrors.push({
                path: 'userId',
                message: 'TR Employee ID cannot be empty'
            });
        }

        const doesUserAlreadyHavePermission = existingPermissions.find(({ userId }) => userId === userPermission.userId);
        if (doesUserAlreadyHavePermission) {
            validationErrors.push({
                path: 'userId',
                message: `Employee ${userPermission.userId} already has permissions`
            });
        }

        if (userPermission.permissions.length === 0) {
            validationErrors.push({
                path: 'permissions',
                message: 'Read or Write Permission must be selected'
            });
        }

        setErrors(validationErrors);

        return validationErrors.length === 0;
    };
    const handleAddUser = async () => {
        setMessages([]);

        if (!validatePermissions()) {
            return;
        }

        try {
            const newPermission = await putPermission(token, userPermission);

            onAdd(newPermission);
            setMessages([{
                type: 'success',
                message: 'Permission successfully created'
            }]);
            setUserPermission(createEmptyPermission(distributionName));
        } catch (err) {
            setMessages([{
                type: 'danger',
                message: 'Permission failed to create'
            }]);
        }
    };

    return (
        <Form>
            <Row>
                <DisplayMessages messages={messages} />
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>TR Employee ID</Form.Label>
                    <Form.Control
                        required
                        id="userId"
                        placeholder="TR Employee ID"
                        value={userPermission.userId}
                        onChange={updateUserId}
                    />
                    <Error path="userId" errors={errors} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Permission</Form.Label>
                    <span className="input-group">
                        <Form.Select aria-label="Floating label select example" value={getSelectValue()} onChange={updatePermissions}>
                            <option value="" disabled>Select Permission</option>
                            <option value="read">Read</option>
                            <option value="write">Write</option>
                        </Form.Select>
                        <LoadingButton onClick={handleAddUser} className="btn" variant="primary" style={{ marginLeft: '20px', borderRadius: '0.357rem' }}>Add User</LoadingButton>
                    </span>
                    <Error path="permissions" errors={errors} />
                </Form.Group>
                <Col />
            </Row>
        </Form>
    );
};

export default AddPermission;
