import React, { useState } from 'react';
import { Row, Col, Form, Container, Tab, Nav, Accordion, useAccordionButton } from 'react-bootstrap';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import CustomHeader from './CustomHeader';
import Behaviors from './Behaviors';
import ErrorComponent from '../../util/Error';
import { createEmptyBehavior, createEmptyCustomHeader } from '../../util/createEmptyObjects';
import Button from '../../components/Button';
import ApigeeMetaData from './originVerifyMetaData/ApigeeMetaData';
import AwsMetaData from './originVerifyMetaData/AwsMetaData';
import { useDistribution } from './DistributionProvider';
import joifyErrorPath from '../../util/joifyErrorPath';

const DeleteBehaviorIcon = ({ eventKey, callback }) => {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        callback
    );

    return (
        <IoMdRemoveCircleOutline
            onClick={decoratedOnClick}
            style={{ color: 'red', marginRight: 10 }}
        />
    );
};

const Origin = ({ origin, parentPath, path, displayDelete, index, assignedDefaultState, defaultOriginExists, eventKeyId }) => {
    const [selectedTab, setSelectedTab] = useState('first');
    const { dispatch, ACTIONS, errors } = useDistribution();

    const hasDefaultBehavior = () => {
        return origin.behaviors.some((behavior) => behavior.isDefaultBehavior);
    };

    const originErrors = errors.filter((error) => error.path.startsWith(joifyErrorPath(path)));

    const customHeaders = origin.customHeaders || [];
    const addCustomHeader = () => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: {
                ...origin,
                customHeaders: [...customHeaders, createEmptyCustomHeader()]
            }
        });
    };

    const buildCustomHeaderList = () => {
        return customHeaders.map((customHeader, headerIndex) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <Form.Group key={`customHeaders-${headerIndex}`}>
                    <Row>
                        <CustomHeader customHeader={customHeader} parentPath={`${path}.customHeaders`} path={`${path}.customHeaders[${headerIndex}]`} index={headerIndex} errors={errors} />
                    </Row>
                </Form.Group>
            );
        });
    };

    const changeDefaultOrigin = () => {
        if (hasDefaultBehavior()) {
            const behaviorsWithoutDefault = origin.behaviors.filter((behavior) => !behavior.isDefaultBehavior);
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...origin,
                    behaviors: [
                        ...behaviorsWithoutDefault
                    ]
                }
            });
        } else {
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...origin,
                    behaviors: [
                        ...origin.behaviors,
                        createEmptyBehavior(true)
                    ]
                }
            });
        }
    };

    const handleOriginOnChange = (event) => {
        switch (event.type) {
            case ('updateBehaviors'): {
                dispatch({
                    type: ACTIONS.UPDATE,
                    path,
                    value: {
                        ...origin,
                        behaviors: event.behaviors
                    }
                });
                break;
            }
            case ('deleteBehavior'): {
            // TODO: handle this case when we handle behaviors as list
                break;
            }
            case ('updateOrigin'): {
                dispatch({
                    type: ACTIONS.UPDATE,
                    path,
                    value: event.origin
                });
                break;
            }
            default:
                dispatch({
                    type: ACTIONS.UPDATE,
                    path,
                    value: {
                        ...origin,
                        [event.target.id]: event.target.value
                    }
                });
                break;
        }
    };

    const getNavLink = (eventKey, text) => {
        // In order to handle Bento formatting we need to get the current selected tab and set the background to be blue, not gray
        if (eventKey === selectedTab) {
            return (
                <Nav.Link className="adnPrimaryNavLink" eventKey={eventKey}>{text}</Nav.Link>
            );
        }

        const hiddenTabKey = text === 'Behaviors' ? 'behaviors' : 'customHeaders';
        const hiddenTabErrors = errors.filter((error) => error.path.startsWith(joifyErrorPath(`${path}.${hiddenTabKey}`)));

        return (
            <span className="d-flex flex-row align-items-baseline">
                <Nav.Link eventKey={eventKey}>{text}</Nav.Link>
                {hiddenTabErrors.length > 0 ? <span className="error-notification"> error </span> : undefined}
            </span>
        );
    };

    const handleTabSelect = (event) => {
        setSelectedTab(event);
    };

    const handleDeleteOrigin = () => {
        dispatch({
            type: ACTIONS.DELETE,
            path: parentPath,
            index
        });
    };

    const toggleOriginVerify = () => {
        if (origin.originVerify) {
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...origin,
                    originVerify: undefined
                }
            });
        } else {
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...origin,
                    originVerify: {
                        originType: '',
                        originMetaData: {}
                    }
                }
            });
        }
    };

    const changeOriginVerifyType = (event) => {
        if (event.target.value === '') {
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...origin,
                    originVerify: {
                        originType: '',
                        originMetaData: {}
                    }
                }
            });
        }

        if (event.target.value === 'apigee') {
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...origin,
                    originVerify: {
                        originType: 'apigee',
                        originMetaData: {
                            environment: '',
                            organization: '',
                            virtualHost: ''
                        }
                    }
                }
            });
        }

        if (event.target.value === 'AWS') {
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...origin,
                    originVerify: {
                        originType: 'AWS',
                        originMetaData: {
                            accountId: ''
                        }
                    }
                }
            });
        }
    };

    const getOriginVerifyMetaData = () => {
        const { originVerify } = origin;
        if (originVerify.originType === '') {
            return undefined;
        }

        if (originVerify.originType === 'apigee') {
            return (<ApigeeMetaData originMetaData={originVerify.originMetaData} path={`${path}.originVerify.originMetaData`} />);
        }

        if (originVerify.originType === 'AWS') {
            return (<AwsMetaData originMetaData={originVerify.originMetaData} path={`${path}.originVerify.originMetaData`} />);
        }

        return undefined;
    };

    const showSecretName = () => {
        return (
            <>
                <Form.Group as={Col}>
                    <Form.Label>
                        Secret Name
                    </Form.Label>
                    <Form.Control required id="secretName" placeholder="Secret Name" value={origin.originVerify.secretName} readOnly />
                    <ErrorComponent path={`${path}.originVerify.secretName`} errors={errors} />
                </Form.Group>
                <Col />
            </>
        );
    };

    const displayDeleteButton = () => {
        return (!hasDefaultBehavior() && displayDelete) ? (
            <DeleteBehaviorIcon callback={handleDeleteOrigin} />
        ) : null;
    };

    return (
        <Accordion defaultActiveKey={assignedDefaultState ? eventKeyId : 'x'} key={eventKeyId}>
            <Accordion.Item eventKey={eventKeyId}>
                <Accordion.Header>
                    <Container style={{ paddingLeft: 0, marginLeft: '0px' }}>
                        Origin
                        {' '}
                        {origin.originDomainName}
                        {originErrors.length > 0 ? <span className="error-notification"> error </span> : undefined}
                    </Container>
                    {displayDeleteButton()}
                </Accordion.Header>
                <Accordion.Body>
                    <Form.Group as={Col}>
                        { !assignedDefaultState ? (
                            <Form.Check
                                type="switch"
                                id={`defaultOriginSwitch-${origin.uiId}`}
                                label="Default Origin"
                                disabled={defaultOriginExists() && !hasDefaultBehavior()}
                                onChange={changeDefaultOrigin}
                                checked={hasDefaultBehavior()}
                            />
                        ) :
                            null}
                        <ErrorComponent path={`${path}.isDefaultOrigin`} errors={errors} />
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                Origin Domain Name
                                <span className="required-field"> *</span>
                            </Form.Label>
                            <Form.Control required id="originDomainName" onChange={handleOriginOnChange} placeholder="Origin Domain Name" value={origin.originDomainName} />
                            <ErrorComponent path={`${path}.originDomainName`} errors={errors} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Origin Path</Form.Label>
                            <Form.Control id="originPath" onChange={handleOriginOnChange} placeholder="Origin Path" value={origin.originPath} />
                            <ErrorComponent path={`${path}.originPath`} errors={errors} />
                        </Form.Group>
                    </Row>
                    <br />
                    <Tab.Container onSelect={handleTabSelect} id="left-tabs-example" defaultActiveKey={selectedTab} activeKey={selectedTab}>
                        <Row>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        {getNavLink('first', 'Behaviors')}
                                    </Nav.Item>
                                    <Nav.Item>
                                        {getNavLink('second', 'Custom Headers')}
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Behaviors behaviors={origin.behaviors} path={`${path}.behaviors`} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Form.Group as={Col}>
                                            {buildCustomHeaderList()}
                                            <br />
                                            <Button onClick={addCustomHeader}>Add Custom Header</Button>
                                            <br />
                                            <br />
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Check
                                type="checkbox"
                                label="Does all incoming traffic go through this distribution?"
                                onClick={toggleOriginVerify}
                                defaultChecked={origin.originVerify}
                            />
                            <Col />
                            {origin.originVerify ? (
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>
                                            Origin Type
                                            <span className="required-field"> *</span>
                                        </Form.Label>
                                        <Form.Select required id="originType" onChange={changeOriginVerifyType} value={origin.originVerify.originType}>
                                            <option value="">select</option>
                                            <option value="apigee">apigee</option>
                                            <option value="AWS">AWS</option>
                                        </Form.Select>
                                        <ErrorComponent path={`${path}.originVerify.originType`} errors={errors} />
                                    </Form.Group>
                                    <Col />
                                </Row>
                            ) :
                                undefined}
                        </Form.Group>
                    </Row>
                    {origin.originVerify && origin.originVerify.originMetaData ? getOriginVerifyMetaData() : undefined}
                    {origin.originVerify && origin.originVerify.secretName ? showSecretName() : undefined}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default Origin;
