import React, { useState, createContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useAuthentication } from '../../authentication/Authentication';
import getDistributionNames from '../../util/api/getDistributionNames';
import getDistribution from '../../util/api/getDistribution';
import getDistributionStatus from '../../util/api/getDistributionStatus';
import getDistributionPermissionsForUser from '../../util/api/getDistributionPermissionsForUser';
import deleteDistribution from '../../util/api/deleteDistribution';

const DistributionsListContext = createContext();

const PENDING_STATUSES = ['PENDING', 'DRAFT', 'DEPLOYING'];

const DistributionsListProvider = ({ children }) => {
    const [isListeningToHistory, setIsListeningToHistory] = useState(false);
    // when we move back to the  page, this will be set to true and trigger an update
    const [updateDistributions, setUpdateDistributions] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [distributions, setDistributions] = useState(undefined);
    const [isFetching, setIsFetching] = useState(false);
    const history = useHistory();

    const { token, getUserId } = useAuthentication();

    const setDistributionsIsLoading = (isLoading) => {
        if (!distributions) {
            return;
        }

        const updatedDistributions = distributions.map((x) => {
            return {
                ...x,
                isLoading
            };
        });

        setDistributions(updatedDistributions);
    };

    const fetchDistributions = async () => {
        setIsFetching(true);
        setErrorMessage(undefined);
        setDistributionsIsLoading(true);

        const currentDistributionsAsHashMap = distributions ?
            distributions.reduce((accumulator, x) => {
                return {
                    ...accumulator,
                    [x.distributionName]: x
                };
            }, {}) :
            {};

        const distributionNames = await getDistributionNames(token);
        const userId = getUserId();

        const distributionsPromises = distributionNames.map(async (name) => {
            const status = await getDistributionStatus(token, name);
            const permissions = await getDistributionPermissionsForUser(token, name, userId);

            // we already have the distribution locally
            // update status and permissions
            if (currentDistributionsAsHashMap[name]) {
                return {
                    ...currentDistributionsAsHashMap[name],
                    status: status.toLowerCase(),
                    permissions,
                    isLoading: false
                };
            }

            let distribution;
            if (PENDING_STATUSES.includes(status)) {
                distribution = await getDistribution(token, name, true);
            }

            if (status === 'DEPLOYED') {
                distribution = await getDistribution(token, name, false);
            }

            if (status === 'DELETING') {
                const distributionNameRegex = /(.+)_(.+)/gm;
                const matches = distributionNameRegex.exec(name);

                distribution = {
                    productFamily: matches[1],
                    environment: matches[2]
                };
            }

            distribution.status = status.toLowerCase();
            distribution.permissions = permissions;

            return distribution;
        });

        try {
            const allDistributions = await Promise.all(distributionsPromises);
            setDistributions(allDistributions);
        } catch (err) {
            setDistributionsIsLoading(false);
            setErrorMessage('Failed to get the distributions, please try again');
        }

        setIsFetching(false);
        setUpdateDistributions(false);
    };

    const refreshDistributionsList = async () => {
        if (!isFetching) {
            await fetchDistributions();
        }
    };

    const handleDeleteDistribution = async (distributionName) => {
        await deleteDistribution(token, distributionName, true);

        // if distribution still exists, update the status
        try {
            const status = await getDistributionStatus(token, distributionName);
            const updatedDistributions = distributions.map((x) => {
                if (x.distributionName === distributionName) {
                    return {
                        ...x,
                        status: status.toLowerCase()
                    };
                }

                return x;
            });

            setDistributions(updatedDistributions);
        } catch (err) {
            // no longer exists (was pending state, remove the distribution from list)
            const updatedDistributions = distributions.filter((x) => x.distributionName !== distributionName);
            setDistributions(updatedDistributions);
        }
    };

    useEffect(async () => {
        if (!token) {
            return;
        }

        if (!distributions && !isFetching) {
            await refreshDistributionsList();
        }
    }, [token]);

    useEffect(() => {
        if (!isListeningToHistory) {
            setIsListeningToHistory(true);

            return history.listen((location) => {
                if (location.pathname === '' || location.pathname === '/') {
                    setUpdateDistributions(true);
                }
            });
        }

        return undefined;
    }, [history]);

    useEffect(async () => {
        if (token && updateDistributions && !isFetching) {
            await fetchDistributions();
        }
    }, [updateDistributions]);

    const value = useMemo(() => {
        return {
            distributions,
            refreshDistributionsList,
            handleDeleteDistribution,
            errorMessage,
            isFetching
        };
    }, [distributions, errorMessage, isFetching]);

    return (
        <DistributionsListContext.Provider value={value}>
            {children}
        </DistributionsListContext.Provider>
    );
};

const useDistributionsList = () => {
    const context = React.useContext(DistributionsListContext);

    if (context === undefined) {
        throw new Error('useDistribution can only be used within a DistributionsListContext');
    }

    return context;
};

export {
    DistributionsListProvider,
    useDistributionsList
};
