import React from 'react';
import { Form, Col } from 'react-bootstrap';
import Button from '../../components/Button';
import ErrorComponent from '../../util/Error';
import { useDistribution } from './DistributionProvider';

const Domain = ({ domain, index, path, parentPath }) => {
    const { dispatch, ACTIONS, errors } = useDistribution();

    const deleteDomain = () => {
        dispatch({
            type: ACTIONS.DELETE,
            path: parentPath,
            index
        });
    };

    const handleDomainNameChange = (event) => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: {
                ...domain,
                domainName: event.target.value
            }
        });
    };

    return (
        <>
            <Form.Group as={Col}>
                <Form.Label>
                    Domain Name
                </Form.Label>
                <Form.Control placeholder="Domain Name" id={path} value={domain.domainName} onChange={handleDomainNameChange} />
                <ErrorComponent path={`${path}.domainName`} errors={errors} />
            </Form.Group>
            <Col>
                <Form.Label>
                    Delete Domain
                </Form.Label>
                <br />
                <Button variant="danger" onClick={deleteDomain}>-</Button>
            </Col>
        </>
    );
};

export default Domain;
