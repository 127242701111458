import React, { useEffect, useState, useMemo, ReactNode, FunctionComponent } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

const AuthenticationContext = React.createContext<AuthStateValue | undefined>(undefined);

interface AuthenticationProviderProps {
    children: ReactNode
}

interface JwtDecode {
    iss: string
    sub: string
    aud: string
    iat: number
    exp: number
    azp: string
    scope: string[]
}

interface AuthState {
    token: string
    decodedToken: undefined | JwtDecode
    user: undefined | User
    isAuthenticated: boolean
    scopes: string[]
}

interface AuthStateValue extends AuthState {
    getUserId: () => string
}

const AuthenticationProvider: FunctionComponent<AuthenticationProviderProps> = ({ children }) => {
    const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user } = useAuth0();
    const [authState, setAuthState] = useState<AuthState>({
        token: '',
        isAuthenticated,
        decodedToken: undefined,
        user: undefined,
        scopes: []
    });

    useEffect(() => {
        const accessError = window.location.href.includes('?error=');
        if (!accessError && !isAuthenticated && !isLoading) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        const updateAuthState = async () => {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUDIENCE,
                scope: process.env.REACT_APP_SCOPES
            });

            const decodedToken = jwtDecode(token) as JwtDecode;

            const authInformation: AuthState = {
                token,
                decodedToken,
                user,
                scopes: decodedToken.scope,
                isAuthenticated
            };

            setAuthState(authInformation);
        };

        if (isAuthenticated) {
            updateAuthState();
        }
    }, [isAuthenticated]);

    const getUserId = () => authState.user && authState.user['https://tr.com/federated_user_id'];

    const value = useMemo(() => {
        return {
            ...authState,
            getUserId
        };
    }, [authState.token, isAuthenticated]);

    return (
        <AuthenticationContext.Provider value={value}>
            { children }
        </AuthenticationContext.Provider>
    );
};

const useAuthentication = () => {
    const context = React.useContext(AuthenticationContext);

    if (context === undefined) {
        throw new Error('useAuthentication can only be used within an AuthenticationContext');
    }

    return context;
};

export {
    useAuthentication,
    AuthenticationProvider
};
