/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Button as BootStrapButton } from 'react-bootstrap';

const Button = (props) => {
    const variant = props.variant;
    const className = props.className ? props.className : '';

    if (!variant || variant === 'primary') {
        return (
            <BootStrapButton {...props} className={`${className} adnPrimaryBtn`}>{ props.children }</BootStrapButton>
        );
    }

    return (
        <BootStrapButton {...props}>{ props.children }</BootStrapButton>
    );
};

export default Button;
