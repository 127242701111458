import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LoadingButton from '../../../util/LoadingButton';
import { useDistribution } from '../DistributionProvider';
import Domains from '../Domains';
import CertificateArn from '../CertificateArn';
import CancelCreateOrEditButton from '../../../util/CancelCreateOrEditButton';

const DomainAndCertPage = ({ onBack, onForward }) => {
    const { distribution, errors } = useDistribution();

    return (
        <>
            <Row>
                <Col>Add Certificate Arn and Domains</Col>
            </Row>
            <Domains domains={distribution.domains || []} path="domains" />
            <Row>
                <CertificateArn certificateArn={distribution.certificateArn || ''} path="certificateArn" errors={errors} />
                <Col />
            </Row>
            <Row>
                <Col className="text-left">
                    <CancelCreateOrEditButton>Cancel</CancelCreateOrEditButton>
                </Col>
                <Col className="col-auto">
                    <LoadingButton style={{ marginRight: '5px' }} onClick={onBack}>Back</LoadingButton>
                    <LoadingButton onClick={onForward}>Save Changes</LoadingButton>
                </Col>
            </Row>
        </>
    );
};

export default DomainAndCertPage;
