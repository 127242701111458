import fetch from 'node-fetch';
import { Permission } from '../../types/permissions';

const putPermission = async (token: string, permission: Permission): Promise<Permission> => {
    const { userId, distributionName } = permission;

    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/permissions/users/${userId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            permissions: permission.permissions
        })
    });

    if (!response.ok) {
        throw new Error(`Failed to create/update permission, got status code: ${response.status}`);
    }

    return response.json();
};

export default putPermission;
