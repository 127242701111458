import fetch from 'node-fetch';
import { Distribution } from '../../types/distribution';

const deployDistribution = async (token: string, distributionName: string): Promise<Distribution> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/deploy`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json '
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to deploy Distribution ${distributionName}, got status code ${response.status}`);
    }

    const distribution = (await response.json()) as Distribution;

    return distribution;
};

export default deployDistribution;
