const copyDistribution = (distribution) => {
    const newDistribution = {
        ...distribution,
        productFamily: undefined,
        environment: undefined,
        distributionId: undefined,
        distributionName: undefined,
        certificateArn: undefined,
        internals: undefined,
        status: undefined,
        domains: undefined
    };

    const removeIds = (object) => {
        return Object.keys(object).reduce((accumulator, key) => {
            const value = accumulator[key];

            if (Array.isArray(value)) {
                return {
                    ...accumulator,
                    [key]: value.map((x) => removeIds(x))
                };
            }

            if (key === 'id') {
                return {
                    ...accumulator,
                    uiId: value,
                    [key]: undefined
                };
            }

            return accumulator;
        }, object);
    };

    return removeIds(newDistribution);
};

export default copyDistribution;
