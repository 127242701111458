import React, { useState } from 'react';
import { FcFilledFilter } from 'react-icons/fc';

const TableFilter = ({ onFilter, column }) => {
    const [show, setShow] = useState(true);

    const handleClick = () => {
        setShow(!show);
    };

    return (
        <>
            <button
                type="button"
                style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}
            >
                <FcFilledFilter
                    onClick={() => handleClick()}
                    style={{ color: 'tr', marginLeft: 10 }}
                />
            </button>
            {show ? (
                <input
                    key="input"
                    type="text"
                    placeholder={`Filter ${column.text}`}
                    onChange={(event) => onFilter(event.target.value)}
                />
            ) : <span />}
        </>
    );
};

export default TableFilter;
