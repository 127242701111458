import fetch from 'node-fetch';

const deletePermission = async (token: string, distributionName: string, userId: string) => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/permissions/users/${userId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to delete permission for ${distributionName} ${userId}, got status code ${response.status}`);
    }
};

export default deletePermission;
