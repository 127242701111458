import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useHistory } from 'react-router';

const TopNavigation = () => {
    const history = useHistory();

    return (
        <Navbar bg="dark" variant="dark">
            <Container fluid>
                <Navbar.Brand onClick={() => history.push('/')}>ADN Management UI</Navbar.Brand>
            </Container>
        </Navbar>
    );
};

export default TopNavigation;
