import React, { useState, useEffect } from 'react';
import { Nav, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import PublishIcon from '@material-ui/icons/Publish';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './Navigation.css';

const initIsCollapsed = () => {
    // using localstorage allows a user to keep a stored state in-between sessions
    const storageValue = localStorage.getItem('adnNavCollapsed');

    return storageValue === 'true';
};

const Navigation = () => {
    const [isCollapsed, setIsCollapsed] = useState(initIsCollapsed());
    const history = useHistory();

    useEffect(() => {
        localStorage.setItem('adnNavCollapsed', isCollapsed);
    }, [isCollapsed]);

    if (isCollapsed) {
        return (
            <Col xs={2} id="sidebar-wrapper-collapsed" className="sidebarColCollapsed">
                <Nav
                    className="d-none d-md-block bg-light sidebarCollapsed"
                >
                    <Nav.Item>
                        <Nav.Link>
                            <ArrowForwardIosIcon onClick={() => setIsCollapsed(false)} />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => history.push('')}>
                            <HomeIcon />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => history.push('/distributions/page/main')}>
                            <AddCircleOutlineIcon />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => history.push('/oad')}>
                            <PublishIcon />
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Col>
        );
    }

    return (
        <Col xs={2} id="sidebar-wrapper" className="sidebarCol">
            <Nav
                className="col-md-12 d-none d-md-block bg-light sidebar"
            >
                <Nav.Item>
                    <Nav.Link onClick={() => setIsCollapsed(true)}>
                        <ArrowBackIosIcon />
                        Collapse
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => history.push('')}>
                        <HomeIcon />
                        Home
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => history.push('/distributions/page/main')}>
                        <AddCircleOutlineIcon />
                        New Distribution
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => history.push('/oad')}>
                        <PublishIcon />
                        Upload OpenAPI Document
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </Col>
    );
};

export default Navigation;
