import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useDistribution } from './DistributionProvider';

const FormNavigation = ({ pages, currentPage, changePage }) => {
    const { distributionName } = useParams();
    const { checkDistribution } = useDistribution();

    const buildPageLinks = () => {
        return Object.keys(pages).map((key) => {
            const handleOnClick = async () => {
                const result = await checkDistribution();

                // if no errors on the distribution, move to the next page
                if (result) {
                    changePage(key);
                }
            };

            // if we have a new distribution, form navigation is disabled. The user must complete the first page before moving around
            const isActive = key === currentPage || !distributionName;

            if (!distributionName && key === currentPage) {
                return (
                    <Breadcrumb.Item key={key} active={isActive} onClick={handleOnClick} className="adnBreadCrumbSelected">{pages[key]}</Breadcrumb.Item>
                );
            }

            return (
                <Breadcrumb.Item key={key} active={isActive} onClick={handleOnClick} className="adnBreadCrumb">{pages[key]}</Breadcrumb.Item>
            );
        });
    };

    return (
        <Breadcrumb>
            {buildPageLinks()}
        </Breadcrumb>
    );
};

export default FormNavigation;
