import fetch from 'node-fetch';

const deleteDistribution = async (token: string, distributionName: string, pending: boolean) => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}?pending=${pending}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json '
        }
    });

    if (response.status === 404) {
        return;
    }

    if (!response.ok) {
        throw new Error(`Failed to delete Distribution ${distributionName}, got status code ${response.status}`);
    }
};

export default deleteDistribution;
