import React from 'react';
import { useHistory, useParams } from 'react-router';
import Loading from '../../util/Loading';
import StartPage from './pages/StartPage';
import OriginsPage from './pages/OriginsPage';
import DomainAndCertPage from './pages/DomainAndCertPage';
import SummaryPage from './pages/SummaryPage';
import { useDistribution } from './DistributionProvider';
import FormNavigation from './FormNavigation';
import PermissionsPage from './pages/PermissionsPage';
import { createEmptyOrigin } from '../../util/createEmptyObjects';
import { PermissionType } from '../../types/permissions';

interface FormParams {
    distributionName: undefined | string
    page: PageTypes
}

export const enum PageTypes {
    main = 'main',
    origins = 'origins',
    domains = 'domains',
    summary = 'summary',
    permissions = 'permissions'
}

interface PageHash {
    [PageTypes.main]?: string
    [PageTypes.origins]?: string
    [PageTypes.domains]?: string
    [PageTypes.summary]: string
    [PageTypes.permissions]: string
}

const pageDisplay: PageHash = {
    [PageTypes.main]: 'Base Distribution',
    [PageTypes.origins]: 'Origins',
    [PageTypes.domains]: 'Domains',
    [PageTypes.permissions]: 'Permissions',
    [PageTypes.summary]: 'Summary'
};
Object.freeze(pageDisplay);

const pageDisplayReadOnly: PageHash = { [PageTypes.permissions]: 'Permissions', [PageTypes.summary]: 'Summary' };
Object.freeze(pageDisplayReadOnly);

const readOnlyPages = [PageTypes.permissions, PageTypes.summary]

// DistributionForm is responsible for managing the navigation throughout the form
// and provide validation checks as the user moves to various pages in the form
const DistributionForm = () => {
    const history = useHistory();
    const { distributionName, page } = useParams<FormParams>();
    const { distribution, dispatch, ACTIONS, checkDistribution, submitToGithub, userPermission, didDistributionChange } = useDistribution();

    const changePage = (newPage: PageTypes) => {
        if (distributionName) {
            history.push(`/distributions/${distributionName}/page/${newPage}`);
        } else {
            history.push(`/distributions/page/${newPage}`);
        }
    };

    const forwardToOriginsPage = async () => {
        // if the user is going to the origins page and has no default origin, create one
        if (distribution.origins.length === 0) {
            const defaultOrigin = createEmptyOrigin(true, distribution.assetInsightId);
            const path = 'origins';
            const value = [defaultOrigin];

            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value
            });
        }
        changePage(PageTypes.origins);
    };

    const forwardToDomainsPage = async () => {
        if (await checkDistribution()) {
            changePage(PageTypes.domains);
        }
    };

    const backAPage = async () => {
        if (await checkDistribution()) {
            history.goBack();
        }
    };

    const forwardToSummaryPage = async () => {
        if (await checkDistribution()) {
            changePage(PageTypes.summary);
        }
    };

    const forwardToPermissionsPage = async () => {
        if (didDistributionChange()) {
            const result = await submitToGithub();

            if (result) {
                changePage(PageTypes.permissions);
            }
            return;
        }

        changePage(PageTypes.permissions);
    };

    const renderCorrectPage = () => {
        switch (page) {
            case (PageTypes.main): {
                return (
                    <StartPage
                        onForward={forwardToOriginsPage}
                    />
                );
            }
            case (PageTypes.origins): {
                return (
                    <OriginsPage
                        onBack={backAPage}
                        onForward={forwardToDomainsPage}
                    />
                );
            }
            case (PageTypes.domains): {
                return (
                    <DomainAndCertPage
                        onBack={backAPage}
                        onForward={forwardToPermissionsPage}
                    />
                );
            }
            case (PageTypes.summary): {
                return (
                    <SummaryPage />
                );
            }
            case (PageTypes.permissions): {
                return (
                    <PermissionsPage
                        onForward={forwardToSummaryPage}
                    />
                );
            }
            default: {
                return (
                    <Loading />
                );
            }
        }
    };

    if (!userPermission) {
        return (
            <p>You do not have access to this distribution</p>
        );
    }

    if (!userPermission.includes(PermissionType.write) && !readOnlyPages.includes(page)) {
        return (
            <>
                <FormNavigation pages={pageDisplayReadOnly} currentPage={page} changePage={changePage} />
                <p>You only have read access to this distribution. Please change the page</p>
            </>
        );
    }

    return (
        <>
            <FormNavigation pages={userPermission.includes(PermissionType.write) ? pageDisplay : pageDisplayReadOnly} currentPage={page} changePage={changePage} />
            {renderCorrectPage()}
        </>
    );
};

export default DistributionForm;
