import fetch from 'node-fetch';

const uploadOad = async (token: string, document: any) => {
    const response = await fetch(`${process.env.REACT_APP_OAD_ANALYTICS_BASE_URL}/v1/documents`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json ',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(document)
    });

    if (response.status === 400) {
        const body = await response.json();
        return {
            status: 400,
            errors: body.validation.errors
        };
    }

    if (!response.ok) {
        return {
            status: response.status
        };
    }

    const result = await response.json();
    return {
        status: response.status,
        result
    };
};

export default uploadOad;
