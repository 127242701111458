import React from 'react';
import Behavior from './Behavior';
import { createEmptyBehavior } from '../../util/createEmptyObjects';
import Button from '../../components/Button';
import { useDistribution } from './DistributionProvider';

const Behaviors = ({ behaviors, path }) => {
    const { dispatch, ACTIONS } = useDistribution();

    const behaviorsLength = behaviors.length;

    const addBehavior = () => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: [...behaviors, createEmptyBehavior()]
        });
    };

    const buildBehaviorList = () => {
        return behaviors.map((behavior, behaviorIndex) => {
            const id = behavior.id || behavior.uiId;
            return (
                <Behavior behavior={behavior} index={behaviorIndex} displayDelete={behaviorsLength > 1} parentPath={path} path={`${path}[${behaviorIndex}]`} eventKeyId={id} key={id} />
            );
        });
    };

    return (
        <>
            {buildBehaviorList()}
            <br />
            <Button onClick={addBehavior}>Add Behavior</Button>
            <br />
            <br />
        </>
    );
};

export default Behaviors;
