import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Col } from 'react-bootstrap';
import { useAuthentication } from '../../authentication/Authentication';
import Loading from '../../util/Loading';
import DistributionForm from './DistributionForm';
import { DistributionProvider } from './DistributionProvider';
import { createEmptyDistribution } from '../../util/createEmptyObjects';
import getDistribution from '../../util/api/getDistribution';
import getDistributionPermissionsForUser from '../../util/api/getDistributionPermissionsForUser';
import { Distribution } from '../../types/distribution';
import { PermissionType } from '../../types/permissions';

// Distribution Form Manager is responsible for initializing the Distribution Provider
const DistributionFormManager = () => {
    const { distributionName } = useParams<{ distributionName: undefined | string }>();
    const { token, isAuthenticated, getUserId } = useAuthentication();

    // eslint-disable-next-line react/destructuring-assignment
    const [initialDistribution, setInitialDistribution] = useState(createEmptyDistribution());
    const [fetchingDistribution, setFetchingDistribution] = useState<boolean>(false);

    // is the a new distribution, it is already loaded
    const [isLoaded, setIsLoaded] = useState<boolean>(distributionName === undefined);

    // for a new distribution the user will automatically have read & write permissions
    const [initialPermission, setInitialPermission] = useState<PermissionType[]>(distributionName === undefined ? [PermissionType.read, PermissionType.write] : []);

    const [hasPendingChanges, setHasPendingChanges] = useState(false);

    useEffect(() => {
        if (!isAuthenticated || !distributionName || isLoaded) {
            return;
        }

        if (!fetchingDistribution) {
            setFetchingDistribution(true);

            const fetchDistribution = async () => {
                const permission = await getDistributionPermissionsForUser(token, distributionName, getUserId());
                setInitialPermission(permission);

                const pendingDistribution = await getDistribution(token, distributionName, true);
                if (pendingDistribution) {
                    setInitialDistribution(pendingDistribution);
                    setHasPendingChanges(true);
                } else {
                    const currentDistribution = await getDistribution(token, distributionName, false);
                    setInitialDistribution(currentDistribution as Distribution);
                    setHasPendingChanges(false);
                }

                setFetchingDistribution(false);
                setIsLoaded(true);
            };
            fetchDistribution()
                .catch((err) => console.log(err));
        }
    }, [distributionName, token]);

    if (!isAuthenticated || !isLoaded) {
        return (
            <Col>
                <Loading />
            </Col>
        );
    }

    return (
        <DistributionProvider defaultState={initialDistribution} initialPermissions={initialPermission} defaultIsPending={hasPendingChanges}>
            <Col>
                <DistributionForm />
            </Col>
        </DistributionProvider>
    );
};

export default DistributionFormManager;
