import { nanoid } from 'nanoid';
import React from 'react';
import { Table } from 'react-bootstrap';

const Summary = ({ distribution }) => {
    const distributionToDisplay = {
        ...distribution,
        internals: undefined
    };
    const buildArrayList = (elements) => {
        if (elements[0] && typeof elements[0] === 'object') {
            if ('domainName' in elements[0]) {
                return (
                    <ul key="domains">
                        {elements.map((element) => {
                            return (<li key={element.domainName}>{`${element.domainName}`}</li>);
                        })}
                    </ul>
                );
            }

            if ('name' in elements[0] && 'value' in elements[0]) {
                return (
                    <ul key={nanoid()}>
                        {elements.map((element) => {
                            return (<li key={element.name}>{`name: ${element.name}, value: ${element.value}`}</li>);
                        })}
                    </ul>
                );
            }
        }

        return (
            <ul key={nanoid()}>
                {elements.map((element) => {
                    return (<li key={element}>{`${element}`}</li>);
                })}
            </ul>
        );
    };
    const buildSummaryBody = (target) => {
        return (
            Object.keys(target).map((key, index) => {
                if (typeof target[key] === 'object') {
                    if (typeof target[key][0] === 'object' && (Object.keys(target[key][0]).length > 2 || key === 'origins' || key === 'behaviors')) {
                        // array of objects
                        return (
                            <tr key={`attribubte-${key}-${index}`}>
                                <td key={key}>{key}</td>
                                <td key={`${key}-values`}>
                                    {target[key].map((x, subIndex) => {
                                        return (
                                            <Table key={`table-${subIndex}`}>
                                                <thead>
                                                    <tr key="columns">
                                                        <th key="attribute">Attribute</th>
                                                        <th key="value">Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {buildSummaryBody(x, subIndex)}
                                                </tbody>
                                            </Table>
                                        );
                                    })}
                                </td>
                            </tr>
                        );
                    }

                    if (!Array.isArray(target[key])) {
                        // just nested object
                        return (
                            <tr key={`attribubte-${key}`}>
                                <td key={key}>{key}</td>
                                <td key={`${key}-values`}>
                                    <Table key={`table-${key}`}>
                                        <thead>
                                            <tr key="columns">
                                                <th key="attribute">Attribute</th>
                                                <th key="value">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {buildSummaryBody(target[key])}
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        );
                    }

                    return (
                        // array of strings
                        <tr key={`attribute-${key}-${index}`}>
                            <td key={key}>{key}</td>
                            <td key={`${key}-children`}>{buildArrayList(target[key])}</td>
                        </tr>
                    );
                }

                // if the value is undefined don't display it
                if (target[key] === undefined) {
                    return undefined;
                }

                return (
                    // strings
                    <tr key={`attribute-${key}-${index}`}>
                        <td key={key}>{key}</td>
                        <td key={`${key}-value`}>{`${target[key]}`}</td>
                    </tr>
                );
            })
        );
    };

    return (
        <Table>
            <thead>
                <tr key="columns">
                    <th key="attribute">Attribute</th>
                    <th key="value">Value</th>
                </tr>
            </thead>
            <tbody>
                {buildSummaryBody(distributionToDisplay)}
            </tbody>
        </Table>
    );
};

export default Summary;
