import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import ErrorComponent from '../../../util/Error';
import { useDistribution } from '../DistributionProvider';

const ApigeeMetaData = ({ originMetaData, path }) => {
    const { dispatch, ACTIONS, errors } = useDistribution();

    const updateOriginVerify = (event) => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: {
                ...originMetaData,
                [event.target.id]: event.target.value
            }
        });
    };

    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        Apigee Organization
                        <span className="required-field"> *</span>
                    </Form.Label>
                    <Form.Control required id="organization" onChange={updateOriginVerify} placeholder="Apigee Organization" value={originMetaData.organization} />
                    <ErrorComponent path={`${path}.organization`} errors={errors} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>
                        Apigee Environment
                        <span className="required-field"> *</span>
                    </Form.Label>
                    <Form.Control required id="environment" onChange={updateOriginVerify} placeholder="Apigee Environment" value={originMetaData.environment} />
                    <ErrorComponent path={`${path}.environment`} errors={errors} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        Apigee Virtual Host
                        <span className="required-field"> *</span>
                    </Form.Label>
                    <Form.Control required id="virtualHost" onChange={updateOriginVerify} placeholder="Apigee Virtual Host" value={originMetaData.virtualHost} />
                    <ErrorComponent path={`${path}.virtualHost`} errors={errors} />
                </Form.Group>
                <Col />
            </Row>
        </>
    );
};

export default ApigeeMetaData;
