import React from 'react';
import { Form, Col } from 'react-bootstrap';
import ErrorComponent from '../../util/Error';
import { useDistribution } from './DistributionProvider';

const CertificateArn = ({ certificateArn, path, errors }) => {
    const { dispatch, ACTIONS } = useDistribution();

    const handleCertificateArnChange = (event) => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: event.target.value
        });
    };

    return (
        <Form.Group as={Col}>
            <Form.Label>
                Certificate Arn
            </Form.Label>
            <Form.Control placeholder="Certificate Arn" value={certificateArn} onChange={handleCertificateArnChange} />
            <ErrorComponent path="certificateArn" errors={errors} />
            <ErrorComponent path="" errors={errors} />
        </Form.Group>
    );
};

export default CertificateArn;
