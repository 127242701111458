/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import LoadingButton from '../../../util/LoadingButton';
import { useDistribution } from '../DistributionProvider';
import Summary from '../Summary';
import emptyStringToUndefined from '../../../util/emptyStringToUndefined';
import CancelCreateOrEditButton from '../../../util/CancelCreateOrEditButton';
import Deploy from '../Deploy';

const SummaryPage = () => {
    const history = useHistory();
    const { distribution, didDistributionChange, hasPendingChanges, getDistributionName } = useDistribution();

    const [isDeploying, setIsDeploying] = useState(false);

    const onBack = () => {
        history.goBack();
    };

    const summaryFooter = () => {
        return (
            <Row>
                <Col className="text-left">
                    <LoadingButton style={{ marginRight: 10 }} onClick={onBack}>Back</LoadingButton>
                </Col>
                <Col className="col-auto">
                    {
                        hasPendingChanges ?
                        <Deploy
                            key={getDistributionName()}
                            padRight={hasPendingChanges || didDistributionChange()}
                            deployChange={(state) => setIsDeploying(state)}
                        /> :
                        null
                    }
                    <Button onClick={() => history.push('/')}>{'Home'}</Button>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Row>
                <Col>Current Distribution Summary:</Col>
            </Row>
            <Summary distribution={emptyStringToUndefined(distribution)} />
            {summaryFooter()}
        </>
    );
};

export default SummaryPage;
