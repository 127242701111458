import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ErrorBanner from '../../../components/ErrorBanner';
import ErrorComponent from '../../../util/Error';
import LoadingButton from '../../../util/LoadingButton';
import Authorization from '../Authorization';
import { useDistribution } from '../DistributionProvider';
import CancelCreateOrEditButton from '../../../util/CancelCreateOrEditButton';
import { useAuthentication } from '../../../authentication/Authentication';

interface StartPageProps {
    onForward: Function
}

const StartPage = ({ onForward }: StartPageProps) => {
    const { token } = useAuthentication();

    const { distribution, dispatch, ACTIONS, hasPendingChanges, errors, refreshDistribution, submitToGithub } = useDistribution();
    const distributionName = distribution.distributionName;

    const handleOnChange = (event: React.ChangeEvent<any>) => {
        const path = event.target.id;
        const value = event.target.value;

        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value
        });
    };

    // determines weather product family, environment and asset insight id are editable
    const isDisabled = (): boolean => !!distribution.distributionName;

    const nextPage = async () => {
        // attempt to create or save the distribution
        const success = await submitToGithub();
        if (success) {
            await onForward();
        }
    };

    const displayUnknownErrors = () => {
        const unknownErrors = errors.filter((x) => x.path === '');
        return unknownErrors.map(({ message }) => {
            return (
                <ErrorBanner message={message} key={message} />
            );
        });
    };

    return (
        <>
            { !distribution.distributionName ? undefined : (
                <Row>
                    <Col>
                        <span>If you made recent changes, please refresh to see the latest data.</span>
                    </Col>
                    <Col>
                        <LoadingButton
                            className="float-end"
                            onClick={refreshDistribution}
                        >
                            Refresh Distribution
                        </LoadingButton>
                    </Col>
                </Row>
            )}
            {displayUnknownErrors()}
            <Form>
                <Row>
                    <Form.Text as={Col}>
                        {!distributionName ? 'Create' : 'Edit'}
                        {' '}
                        a Distribution
                        {hasPendingChanges ? '\'s Pending Changes' : undefined}
                    </Form.Text>
                </Row>
                <Row>
                    <ErrorComponent path="naming" errors={errors} />
                    <Form.Group as={Col}>
                        <Form.Label>
                            Product Family
                            <span className="required-field"> *</span>
                        </Form.Label>
                        <Form.Control required id="productFamily" onChange={handleOnChange} disabled={isDisabled()} placeholder="Product Family" value={distribution.productFamily} />
                        <ErrorComponent path="productFamily" errors={errors} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Environment
                            <span className="required-field"> *</span>
                        </Form.Label>
                        <Form.Control required id="environment" onChange={handleOnChange} disabled={isDisabled()} placeholder="Environment" value={distribution.environment} />
                        <ErrorComponent path="environment" errors={errors} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Application Asset Insight Id
                            <span className="required-field"> *</span>
                        </Form.Label>
                        <Form.Control required id="assetInsightId" onChange={handleOnChange} disabled={isDisabled()} placeholder="Application Asset Insight Id" value={distribution.assetInsightId} />
                        <ErrorComponent path="assetInsightId" errors={errors} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Waf Policy Type</Form.Label>
                        <Form.Select id="wafPolicyType" onChange={handleOnChange} value={distribution.wafPolicyType}>
                            <option value="">Select a value</option>
                            <option value="block">Block</option>
                            <option value="block-internal">Block Internal</option>
                            <option value="count">Count</option>
                        </Form.Select>
                        <ErrorComponent path="wafPolicyType" errors={errors} />
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <span>Authorization</span>
                        <hr className="hrSpacing" />
                    </Col>
                </Row>
                <Authorization authorization={distribution.authorization || { ...distribution, authorization: { authorizationType: '' } }} path="authorization" errors={errors} token={token} />
            </Form>
            <Row>
                <Col className="text-left">
                    <CancelCreateOrEditButton>Cancel</CancelCreateOrEditButton>
                </Col>
                <Col className="col-auto">
                    <LoadingButton onClick={nextPage}>Next Page</LoadingButton>
                </Col>
            </Row>
        </>
    );
};

export default StartPage;
