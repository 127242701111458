import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

interface ErrorBannerProps {
    message: string
}

const ErrorBanner = ({ message }: ErrorBannerProps) => {
    const [show, setShow] = useState(true);

    if (!show) {
        return null;
    }

    return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
            {message}
        </Alert>
    );
};

export default ErrorBanner;
