import React from 'react';
import { Row, Form, Col, Container, Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import ForwardedHeader from './ForwardedHeader';
import ErrorComponent from '../../util/Error';
import Button from '../../components/Button';
import { useDistribution } from './DistributionProvider';
import joifyErrorPath from '../../util/joifyErrorPath';

const DeleteBehaviorIcon = ({ eventKey, callback }) => {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        callback
    );

    return (
        <IoMdRemoveCircleOutline
            onClick={decoratedOnClick}
            style={{ color: 'red', marginRight: 10 }}
        />
    );
};

const Behavior = ({ behavior, index, displayDelete, path, parentPath, eventKeyId }) => {
    const { dispatch, ACTIONS, errors } = useDistribution();

    const forwardedHeaders = behavior.forwardedHeaders || [];
    let forwardAllHeaders = forwardedHeaders.length === 1 && forwardedHeaders[0] === '*';

    const behaviorErrors = errors.filter((error) => error.path.startsWith(joifyErrorPath(path)));

    const handleBehaviorOnChange = (event) => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: {
                ...behavior,
                [event.target.id]: event.target.value
            }
        });
    };

    const handleDeleteBehavior = () => {
        dispatch({
            type: ACTIONS.DELETE,
            path: parentPath,
            index
        });
    };

    const addForwardedHeader = () => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: {
                ...behavior,
                forwardedHeaders: [...forwardedHeaders, '']
            }
        });
    };

    const buildForwardedHeaderList = () => {
        return forwardedHeaders.map((forwardedHeader, headerListIndex) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <Form.Group as={Row} key={`forwardedHeaders-${headerListIndex}`}>
                    <ForwardedHeader
                        forwardedHeader={forwardedHeader}
                        index={headerListIndex}
                        parentPath={`${path}.forwardedHeaders`}
                        path={`${path}.forwardedHeaders[${headerListIndex}]`}
                        errors={errors}
                        behaviorIndex={index}
                    />
                </Form.Group>
            );
        });
    };

    const displayDeleteButton = () => {
        return !behavior.isDefaultBehavior && displayDelete ? (
            <DeleteBehaviorIcon callback={handleDeleteBehavior} />
        ) : null;
    };

    const changeForwardAllHeadersStatus = () => {
        if (forwardAllHeaders) {
            forwardAllHeaders = false;
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...behavior,
                    forwardedHeaders: []
                }
            });
        } else {
            forwardAllHeaders = true;
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...behavior,
                    forwardedHeaders: ['*']
                }
            });
        }
    };

    return (
        <Accordion defaultActiveKey={index === 0 ? eventKeyId : 'x'}>
            <Accordion.Item eventKey={eventKeyId}>
                <Accordion.Header>
                    <Container style={{ paddingLeft: 0, marginLeft: '0px' }}>
                        Behavior
                        {' '}
                        {behavior.uriPattern}
                        {behaviorErrors.length > 0 ? <span className="error-notification"> error </span> : undefined}
                    </Container>
                    {displayDeleteButton()}
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                Behavior Path
                                <span className="required-field"> *</span>
                            </Form.Label>
                            <Form.Control
                                required
                                id="uriPattern"
                                placeholder="Behavior Path"
                                onChange={handleBehaviorOnChange}
                                value={behavior.isDefaultBehavior ? '*' : behavior.uriPattern}
                                readOnly={behavior.isDefaultBehavior ? true : undefined}
                            />
                            <ErrorComponent path={`${path}.uriPattern`} errors={errors} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                Behavior Application Asset Insight Id
                                <span className="required-field"> *</span>
                            </Form.Label>
                            <Form.Control required id="assetInsightId" onChange={handleBehaviorOnChange} placeholder="Application Asset Insight Id" value={behavior.assetInsightId} />
                            <ErrorComponent path={`${path}.assetInsightId`} errors={errors} />
                        </Form.Group>
                    </Row>
                    <Form.Group as={Row}>
                        <Col>
                            <Form.Check
                                type="switch"
                                id={`forwardAllHeadersSwitch-${behavior.uiId}`}
                                label="Forward All Headers"
                                onChange={changeForwardAllHeadersStatus}
                                checked={forwardAllHeaders}
                            />
                        </Col>
                    </Form.Group>
                    {buildForwardedHeaderList()}
                    <br />
                    <Button onClick={addForwardedHeader} disabled={forwardAllHeaders}>Add Forwarded Header</Button>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default Behavior;
