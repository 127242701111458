import React, { useEffect, useState } from 'react';
import { Offcanvas, ProgressBar, Modal } from 'react-bootstrap';
import { DateTime } from 'luxon';
import LoadingButton from '../../util/LoadingButton';
import { useAuthentication } from '../../authentication/Authentication';
import { useDistribution } from './DistributionProvider';
import getDistributionStatus from '../../util/api/getDistributionStatus';
import deployDistribution from '../../util/api/deployDistribution';

const INCREMENT_TIME_IN_MS = 100;
const POLL_TIME_IN_MS = 10000;

const TEN_MINUTES_IN_MILLISECONDS = 600000;

const Deploy = ({ padRight, deployChange }) => {
    const { token } = useAuthentication();
    const { submitToGithub, distribution, didDistributionChange, distributionIsReadOnly, hasPendingChanges, refreshDistribution, getDistributionName } = useDistribution();

    const [showModal, setShowModal] = useState(false);
    const [isDeploying, setIsDeploying] = useState(false);
    const [intervalId, setIntervalId] = useState(undefined);
    const [deployState, setDeployState] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [showDeployProgress, setShowDeployProgress] = useState(false);

    useEffect(() => {
        // if we are no longer deploying clear the interval to check for the distribution status
        if (!isDeploying && intervalId) {
            clearInterval(intervalId);
        }

        if (intervalId) {
            // clear the interval if we leave the page
            return () => {
                clearInterval(intervalId);
            };
        }

        return () => {};
    }, [isDeploying, intervalId]);

    const startDeployment = async () => {
        setShowDeployProgress(true);
        // if we are already deploying, just reshow the progress
        if (isDeploying) {
            return;
        }

        setIsDeploying(true);
        deployChange(true);

        const distributionName = getDistributionName();
        const startTime = DateTime.now().toMillis();

        let lastPollTime = startTime;
        let distributionStatus;
        const trackProgress = async () => {
            const now = DateTime.now().toMillis();
            const timePassed = now - lastPollTime;

            // enough time has passed, check the distribution status
            if (timePassed >= POLL_TIME_IN_MS) {
                lastPollTime = now;

                distributionStatus = await getDistributionStatus(token, distributionName);
                setDeployState(distributionStatus);
            }

            if (distributionStatus === 'DEPLOYED' || distributionStatus === 'PENDING' || distributionStatus === 'DRAFT') {
                setProgress(100);
                setIsDeploying(false);
                deployChange(false);
                setShowModal(true);
                await refreshDistribution();
            } else {
                // increment progress to 90% over ten minutes
                const bound = TEN_MINUTES_IN_MILLISECONDS;

                const timeSinceStart = now - startTime;
                let newProgress = (timeSinceStart / bound) * 90;

                if (newProgress < 90) {
                    setProgress(newProgress);
                } else {
                    // recalculate progress
                    // do the next 10 over an additional 30 minutes
                    const timeSinceFirstBound = now - (bound + startTime);
                    const newBound = (3 * TEN_MINUTES_IN_MILLISECONDS);
                    const additionalProgress = (timeSinceFirstBound / newBound) * 10;

                    newProgress = 90 + additionalProgress;

                    setProgress(newProgress > 99 ? 99 : newProgress);
                }
            }
        };

        const currentStatus = await getDistributionStatus(token, distributionName);

        // in case we come back to a distribution already being deployed
        if (currentStatus !== 'DEPLOYING') {
            await submitToGithub();

            await deployDistribution(token, distributionName);
        }

        setIntervalId(setInterval(trackProgress, INCREMENT_TIME_IN_MS));
    };

    const buildLabel = () => {
        if (isDeploying) {
            return 'Deploying...';
        }

        if (didDistributionChange()) {
            return 'Save and Deploy';
        }

        if (hasPendingChanges) {
            return 'Deploy';
        }

        return '';
    };

    const buildLoadingButton = () => {
        if (distributionIsReadOnly() || (!showDeployProgress && !didDistributionChange() && !hasPendingChanges)) {
            return (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
            );
        }

        const style = padRight === true ? { marginRight: '10px' } : undefined;
        return (
            <LoadingButton
                onClick={startDeployment}
                style={style}
            >
                {buildLabel()}
            </LoadingButton>
        );
    };

    return (
        <>
            {buildLoadingButton()}
            <Offcanvas show={showDeployProgress} onHide={() => setShowDeployProgress(false)} placement="bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{deployState || 'Starting Deployment...'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ProgressBar now={progress} label={`${Math.floor(progress)}%`} />
                </Offcanvas.Body>
            </Offcanvas>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {deployState === 'DEPLOYED' ?
                            'Distribution Deployed' :
                            'Error'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {deployState === 'DEPLOYED' ?
                        `Distribution ${distribution.distributionName} has been successfully deployed.` :
                        `Distribution ${distribution.distributionName} failed to deploy. Please contact "APP-PLATFORM-OPS-ADN@thomsonreuters.com" for further assistance.`}
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Deploy;
