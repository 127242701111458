import React, { useEffect, useState } from 'react';
import { Table, Alert, Form } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router';
import { useDistribution } from '../DistributionProvider';
import getDistributionPermissions from '../../../util/api/getDistributionPermissions';
import Loading from '../../../util/Loading';
import { useAuthentication } from '../../../authentication/Authentication';
import deletePermission from '../../../util/api/deletePermission';
import AddPermission from '../permissions/AddPermission';
import LoadingButton from '../../../util/LoadingButton';
import putPermission from '../../../util/api/putPermission';
import DisplayMessages from '../../../util/DisplayMessages';

const PermissionsPage = ({ onForward }) => {
    const { distribution, setUserPermission } = useDistribution();
    const { token, getUserId } = useAuthentication();
    const history = useHistory();
    const [messages, setMessages] = useState([]);
    const [distributionPermissions, setDistributionPermissions] = useState(undefined);

    useEffect(async () => {
        if (!distributionPermissions && distribution.distributionName) {
            const permissions = await getDistributionPermissions(token, distribution.distributionName);

            setDistributionPermissions(permissions);
        }
    }, [distributionPermissions]);

    if (!distribution.distributionName) {
        return (
            <Alert variant="warning">
                The distribution must be created before permissions can be added. Save the distribution on the summary page first
            </Alert>
        );
    }

    if (!distributionPermissions) {
        return (
            <Loading />
        );
    }

    const onBack = () => {
        history.goBack();
    };

    const refreshPermissions = async () => {
        const permissions = await getDistributionPermissions(token, distribution.distributionName);

        setDistributionPermissions(permissions);
    };

    const handleAddNewPermission = (permission) => {
        setDistributionPermissions([
            ...distributionPermissions,
            permission
        ]);
    };

    const doesUserHaveWritePermissions = () => {
        const userId = getUserId();

        return distributionPermissions.find((x) => x.userId === userId && x.permissions.includes('write'));
    };

    const onMessageDelete = (key) => {
        const updatedMessages = messages.filter((x) => x.key !== key);

        setMessages(updatedMessages);
    };

    const buildTableBody = () => {
        const buildDeleteColumn = (permission) => {
            const setPermissionDeleting = (isDeleting) => {
                const updatedDistributionPermissions = distributionPermissions.map((x) => {
                    if (x.userId === permission.userId) {
                        return {
                            ...x,
                            deleting: isDeleting
                        };
                    }
                    return x;
                });
                setDistributionPermissions(updatedDistributionPermissions);
            };

            const handleDeletePermission = async () => {
                try {
                    setPermissionDeleting(true);

                    await deletePermission(token, permission.distributionName, permission.userId);

                    const updatedDistributionPermissions = distributionPermissions.filter((x) => x !== permission);
                    setDistributionPermissions(updatedDistributionPermissions);
                } catch (err) {
                    setMessages([
                        ...messages,
                        {
                            type: 'danger',
                            message: `Failed to delete permission for user ${permission.userId}`,
                            key: nanoid()
                        }
                    ]);
                    setPermissionDeleting(false);
                }
            };

            if (!doesUserHaveWritePermissions()) {
                return undefined;
            }

            if (permission.userId === getUserId()) {
                return undefined;
            }

            if (permission.deleting) {
                return (
                    <Loading />
                );
            }

            if (permission.editing) {
                return undefined;
            }

            return (
                <button
                    className="deleteButton"
                    type="button"
                    onClick={() => handleDeletePermission(permission)}
                >
                    <DeleteIcon />
                </button>
            );
        };

        const buildEditColumn = (permission) => {
            const setPermissionEditing = (isPermissionEditing) => {
                const updatedDistributionPermissions = distributionPermissions.map((x) => {
                    if (x.userId === permission.userId) {
                        return {
                            ...x,
                            editing: isPermissionEditing
                        };
                    }

                    return x;
                });

                setDistributionPermissions(updatedDistributionPermissions);
            };

            const handleSaveChanges = async () => {
                try {
                    const updatedPermission = await putPermission(token, permission);

                    const updatedDistributionPermissions = distributionPermissions.map((x) => {
                        return x === permission ? updatedPermission : x;
                    });
                    setDistributionPermissions(updatedDistributionPermissions);

                    // if the user updated their own permission, let hte distribution provider know
                    if (getUserId() === permission.userId) {
                        setUserPermission(permission.permissions);
                    }
                } catch (err) {
                    console.log(err);
                    setMessages([
                        ...messages,
                        {
                            type: 'danger',
                            message: `Failed to update permission for user ${permission.userId}`,
                            key: nanoid()
                        }
                    ]);
                    setPermissionEditing(false);
                }
            };

            if (permission.editing) {
                return (
                    <LoadingButton onClick={handleSaveChanges}>Save Changes</LoadingButton>
                );
            }

            if (!doesUserHaveWritePermissions()) {
                return undefined;
            }

            if (permission.deleting) {
                return undefined;
            }

            return (
                <button
                    className="editButton"
                    type="button"
                    onClick={() => setPermissionEditing(true)}
                >
                    <EditIcon />
                </button>
            );
        };

        const buildPermissionsRow = (permission) => {
            const getSelectValue = () => {
                return permission.permissions.includes('write') ? 'write' : 'read';
            };

            const handleOnPermissionSelect = (event) => {
                const value = event.target.value;

                const updatedDistributionPermissions = distributionPermissions.map((x) => {
                    if (x.userId === permission.userId) {
                        return {
                            ...x,
                            permissions: value === 'read' ? ['read'] : ['read', 'write']
                        };
                    }
                    return x;
                });
                setDistributionPermissions(updatedDistributionPermissions);
            };

            if (permission.editing) {
                return (
                    <Form.Select aria-label="Floating label select example" value={getSelectValue()} onChange={handleOnPermissionSelect}>
                        <option value="read">Read</option>
                        <option value="write">Write</option>
                    </Form.Select>
                );
            }

            return permission.permissions.join(',');
        };

        return distributionPermissions.map((x) => {
            return (
                <tr key={x.userId}>
                    <td>{x.userId}</td>
                    <td>{buildPermissionsRow(x)}</td>
                    <td>{buildDeleteColumn(x)}</td>
                    <td>{buildEditColumn(x)}</td>
                </tr>
            );
        });
    };

    const buildAddUser = () => {
        if (!doesUserHaveWritePermissions()) {
            return undefined;
        }

        return (
            <AddPermission
                distributionName={distribution.distributionName}
                onAdd={handleAddNewPermission}
                existingPermissions={distributionPermissions}
            />
        );
    };

    return (
        <>
            <LoadingButton
                className="float-end"
                style={{ marginBottom: '5px' }}
                onClick={refreshPermissions}
            >
                Refresh
            </LoadingButton>
            <DisplayMessages messages={messages} onDelete={onMessageDelete} />
            <Table>
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Permissions</th>
                        <th>Delete</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {buildTableBody()}
                </tbody>
            </Table>
            {buildAddUser()}
            <LoadingButton styles={{ marginLeft: '10px' }} onClick={onBack}>Back</LoadingButton>
            <LoadingButton styles={{ marginLeft: '10px' }} className="buttonRight" onClick={onForward}>Next Page</LoadingButton>
        </>
    );
};

export default PermissionsPage;
