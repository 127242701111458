import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { createEmptyDomain } from '../../util/createEmptyObjects';
import Domain from './Domain';
import Button from '../../components/Button';
import { useDistribution } from './DistributionProvider';

const Domains = ({ domains, path }) => {
    const { dispatch, ACTIONS } = useDistribution();

    const addNewDomain = () => {
        const newDomains = [...domains, createEmptyDomain()];
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: newDomains
        });
    };

    const buildDomainsList = () => {
        return domains.map((domain, domainIndex) => {
            return (
                <Row key={domain.id || domain.uiId}>
                    <Domain
                        domain={domain}
                        index={domainIndex}
                        path={`${path}[${domainIndex}]`}
                        parentPath={path}
                    />
                    <br />
                </Row>
            );
        });
    };

    return (
        <Row>
            {buildDomainsList()}
            <Row>
                <Col>
                    <Button onClick={addNewDomain}>Add Domain</Button>
                </Col>
            </Row>
        </Row>
    );
};

export default Domains;
