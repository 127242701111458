export const enum WafPolicyTypes {
    count = 'count',
    block = 'block',
    blockInternal = 'block-internal'
}

export const enum OriginTypes {
    aws = 'AWS',
    apigee = 'apigee'
}

export const enum AuthorizationType {
    none = 'none',
    passthrough = 'passthrough',
    full = 'full'
}

interface Distribution {
    distributionName: undefined | string
    productFamily: string
    environment: string
    wafPolicyType: undefined | WafPolicyTypes
    domains: Domain[]
    distributionId: undefined | string
    assetInsightId: string
    createdBy: undefined | string
    createdOn: undefined | string
    stackName: undefined | string
    labels: undefined | string[]
    modifiedOn: undefined | string
    modifiedBy: undefined | string,
    certificateArn: undefined | string
    origins: Origin[],
    authorization: undefined | Authorization
}

interface Authorization {
    authorizationType: AuthorizationType
    environment: undefined | string
    authorizationArn: undefined | string
}

interface Origin {
    id: undefined | string
    uiId: string
    originDomainName: string
    originPath: undefined | string
    originVerify: undefined | OriginVerify
    customHeaders: CustomHeader[]
    behaviors: Behavior[]
}

interface OriginMetaDataAws {
    region: string
    accountId: string
}

interface OriginMetaDataApigee {
    virtualHost: string
    environment: string
    organization: string
}

interface OriginVerify {
    originType: OriginTypes
    originMetaData: OriginMetaDataAws | OriginMetaDataApigee
    secretName: undefined | string
}

interface CustomHeader {
    uiId: string
    name: string
    value: string
}

interface Behavior {
    id: undefined | string
    uiId: string
    assetInsightId: string
    isDefaultBehavior: undefined | boolean
    uriPattern: undefined | string
    forwardedHeaders: undefined | string[]
}

interface Domain {
    uiId: string
    id: undefined | string
    domainName: string
}

export type {
    Distribution,
    Origin,
    Behavior,
    Domain,
    CustomHeader,
    OriginVerify,
    OriginMetaDataApigee,
    OriginMetaDataAws
};
