import fetch from 'node-fetch';
import { Distribution } from '../../types/distribution';
import { ValidateResponse } from '../../types/misc';

const validateDistribution = async (token: string, distribution: Distribution): Promise<ValidateResponse> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/validate`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(distribution)
    });

    if (!response.ok) {
        if (response.status === 400) {
            const result = await response.json();

            return {
                valid: false,
                errors: result.validation.errors
            };
        }

        throw new Error(`Unexpected error when validating the distribution ${response.status}`);
    }

    return {
        valid: true,
        errors: []
    };
};

export default validateDistribution;
