import React from 'react';
import { Alert } from 'react-bootstrap';
import { BsXLg } from 'react-icons/bs';

const DisplayMessages = ({ messages, onDelete }) => {
    return messages.map(({ type, message, key }) => {
        return (
            <Alert key={key} variant={type} onClick={() => onDelete(key)}>
                {message}
                <span style={{ float: 'right' }}><BsXLg /></span>
            </Alert>
        );
    });
};

export default DisplayMessages;
