import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import ErrorComponent from '../../util/Error';
import { useDistribution } from './DistributionProvider';

const Authorization = ({ authorization, path, errors, token }) => {
    const { dispatch, ACTIONS } = useDistribution();

    const [authorizationArnList, setAuthorizationArnList] = useState(undefined);

    useEffect(async () => {
        if (!authorizationArnList && authorization.authorizationType && authorization.environment) {
            const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/authorizationArns/types/${authorization.authorizationType}/environments/${authorization.environment}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json '
                }
            });

            const arnList = await response.json();
            setAuthorizationArnList(arnList);
            dispatch({
                type: ACTIONS.UPDATE,
                path,
                value: {
                    ...authorization,
                    authorizationArn: arnList.find((x) => x.endsWith('$LATEST'))
                }
            });
        }
    }, [authorizationArnList, authorization.authorizationType, authorization.environment]);

    const changeAuthorizationType = (event) => {
        const newAuth = event.target.value !== 'none' ?
            {
                authorizationType: event.target.value,
                environment: authorization.environment || '',
                authorizationArn: ''
            } :
            { authorizationType: event.target.value };
        // reset arn list if authorizationType changes
        setAuthorizationArnList(undefined);

        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: newAuth
        });
    };

    const handleAuthorizationChange = (event) => {
        if (event.target.id !== 'authorizationArn') {
            // reset arn list if environment changes
            setAuthorizationArnList(undefined);
        }
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: {
                ...authorization,
                [event.target.id]: event.target.value
            }
        });
    };

    const populateArnDropDown = () => {
        return (
            <Form.Group as={Col}>
                <Form.Label>
                    Authorization Arn
                </Form.Label>
                <Form.Select id="authorizationArn" value={authorization.authorizationArn} onChange={handleAuthorizationChange}>
                    {authorizationArnList.map((arn) => {
                        return <option key={arn} value={arn}>{arn}</option>;
                    })}
                </Form.Select>
                <ErrorComponent path="authorization.authorizationArn" errors={errors} />
            </Form.Group>
        );
    };

    const renderAdditionalAuthInfo = () => {
        if (authorization.environment !== '' && !authorization.environment) {
            return undefined;
        }

        return (
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        Environment
                        <span className="required-field"> *</span>
                    </Form.Label>
                    <Form.Select required id="environment" onChange={handleAuthorizationChange} value={authorization.environment}>
                        <option value="">select</option>
                        <option value="nonprod">nonprod</option>
                        <option value="prod">prod</option>
                    </Form.Select>
                    <ErrorComponent path="authorization.environment" errors={errors} />
                </Form.Group>
                {authorizationArnList ? populateArnDropDown() : (
                    <Form.Group as={Col}>
                        <Form.Label>
                            Version
                        </Form.Label>
                        <Form.Select id="authorizationArn" value={authorization.authorizationArn} onChange={handleAuthorizationChange}>
                            <option value="">First select Environment</option>
                        </Form.Select>
                        <ErrorComponent path="authorization.authorizationArn" errors={errors} />
                    </Form.Group>
                )}
            </Row>
        );
    };

    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        Type
                        <span className="required-field"> *</span>
                    </Form.Label>
                    <Form.Select required id="authorizationType" onChange={changeAuthorizationType} value={authorization.authorizationType}>
                        <option value="none">none</option>
                        <option value="full">full</option>
                        <option value="passthrough">passthrough</option>
                    </Form.Select>
                    <ErrorComponent path="authorization.authorizationType" errors={errors} />
                </Form.Group>
                <Col />
            </Row>
            {renderAdditionalAuthInfo()}
        </>
    );
};

export default Authorization;
