import React from 'react';
import { Form } from 'react-bootstrap';

const cleanErrorMessagePath = (message) => {
    // example message: "origins[0].customHeaders[0].value" is required
    const messageTerms = message.split('"');
    // array ['', 'origins[0].customHeaders[0].value', ' is required']

    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [space, pathTerm, ...remainingMessage] = messageTerms;

    // if there are further double quotes in the string, rejoin the terms of the array with the quotes added back in
    const reconstructedMessage = remainingMessage.length > 1 ? remainingMessage.join('"') : remainingMessage.pop();

    const path = pathTerm.split('.').pop();
    // last term of ['origins[0]', 'customHeaders[0]', 'value']

    // return '"value" is required'
    return `"${path}"${reconstructedMessage}`;
};

const ErrorComponent = ({ path, errors }) => {
    // Joi path does origins.1.xxx instead of origins[0].xxx
    const cleanPath = path
        .replaceAll('[', '.')
        .replaceAll(']', '');
    try {
        if (errors.length > 0) {
            const error = errors.find((err) => err.path === cleanPath);
            if (error) {
                const errorMessage = error.message.includes('[') ? cleanErrorMessagePath(error.message) : error.message;
                return (
                    <Form.Text className="text-danger">{errorMessage}</Form.Text>
                );
            }
        }
        return <div />;
    } catch (err) {
        console.log(path, errors);
        throw err;
    }
};

export default ErrorComponent;
