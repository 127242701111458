import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownButton, Col, Row, Modal, Button, Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import Loading from '../../util/Loading';
import LoadingButton from '../../util/LoadingButton';
import { useAuthentication } from '../../authentication/Authentication';
import getDistribution from '../../util/api/getDistribution';
import difference from '../../util/getDifferences';
import Summary from '../form/Summary';
import copyDistribution from '../../util/copyDistribution';
import { useDistributionsList } from './DistributionsListProvider';
import TableFilter from '../../components/TableFilter';

const initNumOfDistributionsPerPage = () => {
    // using localstorage to store the number of distributions per page user setted
    const storageValue = localStorage.getItem('numOfDistributionsPerPage');
    if (!storageValue) {
        localStorage.setItem('numOfDistributionsPerPage', '5');
        return 5;
    }
    return parseInt(storageValue);
};

const DistributionsTable = () => {
    const history = useHistory();
    const [selectedDistribution, setSelectedDistribution] = useState(undefined);
    const { distributions, refreshDistributionsList, handleDeleteDistribution, errorMessage, isFetching } = useDistributionsList();

    // variables for pagination
    const [numOfDistributionsPerPage, setNumOfDistributionsPerPage] = useState(initNumOfDistributionsPerPage());

    const { token } = useAuthentication();

    const [modalDetails, setModalDetails] = useState({
        title: undefined,
        display: false,
        distribution: undefined,
        changes: undefined
    });

    const clearModal = () => {
        setModalDetails({
            ...modalDetails,
            display: false
        });
        setTimeout(() => {
            setModalDetails({
                title: undefined,
                display: false,
                distribution: undefined,
                changes: undefined
            });
        }, 200);
    };

    const buildTableBody = () => {
        if (!distributions) {
            return (
                <Col>
                    <Loading />
                </Col>
            );
        }

        return distributions.map((distribution) => {
            const getLink = () => {
                return !distribution.permissions.includes('write') ?
                    `/distributions/${distribution.distributionName}/page/summary` :
                    `/distributions/${distribution.distributionName}/page/main`;
            };

            if (distribution.isLoading) {
                return {
                    id: distribution.distributionName,
                    distributionName: (<Link to={getLink()}>{distribution.distributionName}</Link>),
                    distributionId: distribution.distributionId,
                    productFamily: distribution.productFamily,
                    environment: distribution.environment,
                    status: <Loading />,
                    permissions: undefined,
                    details: distribution
                };
            }

            return {
                id: distribution.distributionName,
                distributionName: (<Link to={getLink()}>{distribution.distributionName}</Link>),
                distributionId: distribution.distributionId,
                productFamily: distribution.productFamily,
                environment: distribution.environment,
                status: distribution.status,
                permissions: distribution.permissions.join(','),
                details: distribution
            };
        });
    };

    const buildModal = () => {
        return (
            <Modal
                show={modalDetails.display}
                onHide={clearModal}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalDetails.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalDetails.changes ? <Summary distribution={modalDetails.changes} /> : <Loading />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={clearModal}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="danger"
                        onClick={async () => {
                            setModalDetails(modalDetails);

                            await handleDeleteDistribution(modalDetails.distribution.distributionName);

                            clearModal();
                        }}
                    >
                        Discard Changes
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        );
    };

    const actionDropdown = () => {
        const viewButton = () => {
            const disable = !(selectedDistribution && selectedDistribution.permissions.includes('read'));

            return (
                <Dropdown.Item
                    disabled={disable}
                    onClick={() => {
                        history.push(`/distributions/${selectedDistribution.distributionName}/page/summary`);
                    }}
                >
                    View
                </Dropdown.Item>
            );
        };

        const deleteButton = () => {
            const disable = !selectedDistribution || !(selectedDistribution.permissions.includes('write') && (selectedDistribution.status === 'draft' || selectedDistribution.status === 'pending'));

            return (
                <Dropdown.Item
                    disabled={disable}
                    onClick={async () => {
                        setModalDetails({
                            ...modalDetails,
                            display: true
                        });
                        if (selectedDistribution.status === 'pending') {
                            const currentDistribution = await getDistribution(token, selectedDistribution.distributionName, false);
                            const pendingDistribution = await getDistribution(token, selectedDistribution.distributionName, true);
                            const differences = difference(currentDistribution, pendingDistribution);
                            delete differences.internals;
                            setModalDetails({
                                title: 'Pending Changes',
                                display: true,
                                changes: differences,
                                distribution: selectedDistribution
                            });
                        } else {
                            const draft = JSON.parse(JSON.stringify(selectedDistribution));
                            delete draft.internals;
                            setModalDetails({
                                title: 'Draft',
                                display: true,
                                changes: draft,
                                distribution: selectedDistribution
                            });
                        }
                    }}
                >
                    Delete
                </Dropdown.Item>
            );
        };

        const editButton = () => {
            const disable = !(selectedDistribution && selectedDistribution.permissions.includes('write'));

            return (
                <Dropdown.Item
                    disabled={disable}
                    onClick={() => {
                        history.push(`/distributions/${selectedDistribution.distributionName}/page/main`);
                    }}
                >
                    Edit
                </Dropdown.Item>
            );
        };

        const copyButton = () => {
            const disable = !(selectedDistribution && selectedDistribution.permissions.includes('read'));

            return (
                <Dropdown.Item
                    disabled={disable}
                    onClick={() => {
                        history.push({ pathname: '/distributions/page/main', state: copyDistribution(selectedDistribution) });
                    }}
                >
                    Copy
                </Dropdown.Item>
            );
        };

        return (
            <DropdownButton title="Actions">
                {viewButton()}
                {editButton()}
                {copyButton()}
                {deleteButton()}
            </DropdownButton>
        );
    };

    const filterById = (filterVal, data) => {
        if (filterVal) {
            return data.filter((distribution) => distribution.id.includes(filterVal));
        }
        return data;
    };

    const columns = [{
        dataField: 'distributionName',
        text: 'Distribution Name',
        filter: customFilter({
            onFilter: filterById
        }),
        filterRenderer: (onFilter, column) => <TableFilter onFilter={onFilter} column={column} />
    }, {
        dataField: 'distributionId',
        text: 'Distribution ID',
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <TableFilter onFilter={onFilter} column={column} />
    }, {
        dataField: 'productFamily',
        text: 'Product Family'
    }, {
        dataField: 'environment',
        text: 'Environment'
    }, {
        dataField: 'status',
        text: 'Status'
    }, {
        dataField: 'permissions',
        text: 'Permissions'
    }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: (distribution) => {
            console.log(distribution.details);
            setSelectedDistribution(distribution.details);
        }
    };

    const pageOptions = {
        sizePerPage: numOfDistributionsPerPage,
        pageStartIndex: 1,
        withFirstAndLast: true,
        firstPageText: '<<',
        prePageText: '<',
        nextPageText: '>',
        lastPageText: '>>',
        showTotal: true,
        disablePageTitle: true,
        // eslint-disable-next-line no-unused-vars
        onSizePerPageChange: (sizePerPage, page) => {
            setNumOfDistributionsPerPage(sizePerPage);
            localStorage.setItem('numOfDistributionsPerPage', sizePerPage);
        },
        onPageChange: (page, sizePerPage) => {
            setNumOfDistributionsPerPage(sizePerPage);
        },
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: '20', value: 20
        }, {
            text: 'All', value: distributions && distributions.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const buildBody = () => {
        if (!distributions) {
            return (
                <Loading />
            );
        }

        return (
            <BootstrapTable
                keyField="id"
                columns={columns}
                data={buildTableBody()}
                selectRow={selectRow}
                bordered={false}
                pagination={paginationFactory(pageOptions)}
                filter={filterFactory()}
                hover
            />
        );
    };

    return (
        <>
            <Col>
                <Row>
                    <Col>
                        <span>If you made recent changes, please refresh to see the latest data.</span>
                    </Col>
                    <Col>
                        <LoadingButton
                            disabled={isFetching}
                            className="float-end"
                            onClick={refreshDistributionsList}
                        >
                            Refresh Distributions
                        </LoadingButton>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {errorMessage ?
                            <Alert variant="danger">{errorMessage}</Alert> :
                            undefined}
                    </Col>
                    <Col>
                        <div className="float-end">
                            {actionDropdown()}
                        </div>
                    </Col>
                </Row>
                {buildBody()}
            </Col>
            {buildModal()}
        </>
    );
};

export default DistributionsTable;
