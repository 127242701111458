import React, { ReactNode, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import Button from '../components/Button';

interface CancelCreateOrEditButtonProps {
    className?: string
    style?: React.CSSProperties
    children: ReactNode
}

const CancelCreateOrEditButton = ({ className, style, children }: CancelCreateOrEditButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const handleOnClick = async () => {
        if (isLoading) {
            // no-op the button is disabled
            return;
        }
        setIsLoading(true);
        history.push('');
        setIsLoading(false);
    };

    return (
        <Button onClick={handleOnClick} className={className} style={style} disabled={isLoading}>
            {children}
            {isLoading ? <Spinner style={{ marginLeft: '5px' }} animation="border" size="sm" /> : undefined}
        </Button>
    );
};

CancelCreateOrEditButton.defaultProps = {
    className: '',
    style: undefined
};

export default CancelCreateOrEditButton;
