import fetch from 'node-fetch';
import { Domain } from '../../types/distribution';
import { ComponentError, ValidateResponse } from '../../types/misc';

const validateDomains = async (token: string, domains: Domain[]): Promise<ValidateResponse> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/domains/validate`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(domains)
    });

    if (!response.ok) {
        if (response.status === 400) {
            const result = await response.json();

            // reformat domain errors to match distribution level errors
            const errors = result.validation.errors.map((err: ComponentError) => {
                if (err.path.endsWith('.domainName')) {
                    return {
                        ...err,
                        path: `domains.${err.path}`
                    };
                }
                return err;
            });

            return {
                valid: false,
                errors
            };
        }

        if (response.status === 409) {
            return {
                valid: false,
                errors: await response.json()
            };
        }

        throw new Error(`Unexpected error when validating domains, got status code ${response.status}`);
    }

    return {
        valid: true,
        errors: []
    };
};

export default validateDomains;
