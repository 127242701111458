import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ErrorComponent from '../../../util/Error';
import LoadingButton from '../../../util/LoadingButton';
import Origins from '../Origins';
import { useDistribution } from '../DistributionProvider';
import CancelCreateOrEditButton from '../../../util/CancelCreateOrEditButton';

const OriginsPage = ({ onBack, onForward }) => {
    const { distribution, errors } = useDistribution();

    return (
        <>
            <Row>
                <Col>Add Origin and Behaviors</Col>
            </Row>
            <Origins origins={distribution.origins} path="origins" />
            <ErrorComponent path="origins" errors={errors} />
            <Row>
                <Col className="text-left">
                    <CancelCreateOrEditButton>Cancel</CancelCreateOrEditButton>
                </Col>
                <Col className="col-auto">
                    <LoadingButton style={{ marginRight: '5px' }} onClick={onBack}>Back</LoadingButton>
                    <LoadingButton onClick={onForward}>Next Page</LoadingButton>
                </Col>
            </Row>
        </>
    );
};

export default OriginsPage;
