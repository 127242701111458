import React from 'react';
import { Form } from 'react-bootstrap';

// These are to display errors from the OAD Storage API
const UploadErrors = ({ errors }) => {
    if (errors.length === 0) {
        return (
            <>
            </>
        );
    }

    return errors.map((error) => {
        const message = error.message
            .replaceAll('[', '')
            .replaceAll(']', '')
            .replace('"value"', 'OpenAPI Document');

        return (
            <Form.Text className="text-danger">{message}</Form.Text>
        );
    });
};

export default UploadErrors;
