import { transform, isEqual, isObject, isArray } from 'lodash';

function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, (result, value, key) => {
        if (!isEqual(value, origObj[key])) {
            // eslint-disable-next-line no-plusplus
            const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
            // eslint-disable-next-line no-param-reassign
            result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value;
        }
    });
}

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
function difference(origObj, newObj) {
    return changes(newObj, origObj);
}

export default difference;
