import fetch from 'node-fetch';
import { Permission, PermissionType } from '../../types/permissions';

const getDistributionPermissionsForUser = async (token: string, distributionName: string, userId: string): Promise<PermissionType[]> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/permissions/users/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error(`Got status code ${response.status} when trying to get permissions for distribution ${distributionName}`);
    }

    const body = (await response.json()) as Permission;
    return body.permissions;
};

export default getDistributionPermissionsForUser;
