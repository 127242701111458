import { nanoid } from 'nanoid';
import { Behavior, Origin, Distribution, CustomHeader, Domain, WafPolicyTypes, AuthorizationType } from '../types/distribution';

const createEmptyBehavior = (isDefaultBehavior = false, assetInsightId = ''): Behavior => {
    return {
        id: undefined,
        uiId: nanoid(),
        assetInsightId,
        isDefaultBehavior,
        forwardedHeaders: [],
        uriPattern: ''
    };
};

const createEmptyOrigin = (isDefaultBehavior = false, assetInsightId = ''): Origin => {
    return {
        id: undefined,
        originVerify: undefined,
        uiId: nanoid(),
        originDomainName: '',
        originPath: '',
        customHeaders: [],
        behaviors: [createEmptyBehavior(isDefaultBehavior, assetInsightId)]
    };
};

const createEmptyDistribution = (): Distribution => {
    return {
        productFamily: '',
        environment: '',
        assetInsightId: '',
        certificateArn: '',
        domains: [],
        wafPolicyType: WafPolicyTypes.block,
        origins: [],
        authorization: {
            authorizationType: AuthorizationType.passthrough,
            environment: '',
            authorizationArn: ''
        },
        distributionId: undefined,
        distributionName: undefined,
        createdBy: undefined,
        createdOn: undefined,
        modifiedBy: undefined,
        modifiedOn: undefined,
        stackName: undefined,
        labels: undefined
    };
};

const createEmptyCustomHeader = (): CustomHeader => {
    return {
        uiId: nanoid(),
        name: '',
        value: ''
    };
};

const createEmptyDomain = (): Domain => {
    return {
        id: undefined,
        uiId: nanoid(),
        domainName: ''
    };
};

export {
    createEmptyDistribution,
    createEmptyBehavior,
    createEmptyOrigin,
    createEmptyCustomHeader,
    createEmptyDomain
};
