import fetch from 'node-fetch';
import { Permission } from '../../types/permissions';

const getDistributionPermissions = async (token: string, distributionName: string): Promise<Permission[]> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/permissions`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error(`Got status code ${response.status} when trying to get permissions for distribution ${distributionName}`);
    }

    return response.json();
};

export default getDistributionPermissions;
