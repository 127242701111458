export const enum PermissionType {
    read = 'read',
    write = 'write'
}

interface Permission {
    userId: string
    distributionName: string
    permissions: PermissionType[]
}

interface DistributionPermissionsHash {
    [key: string]: PermissionType[]
}

export type {
    Permission,
    DistributionPermissionsHash
};
