import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import ErrorComponent from '../../util/Error';
import Button from '../../components/Button';
import { useDistribution } from './DistributionProvider';

const CustomHeader = ({ customHeader, path, parentPath, index, errors }) => {
    const { dispatch, ACTIONS } = useDistribution();

    const handleCustomHeaderChange = (event) => {
        const fieldName = event.target.getAttribute('fieldname');
        const updateCustomHeader = {
            ...customHeader,
            [fieldName]: event.target.value
        };

        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: updateCustomHeader
        });
    };

    const handleDeleteCustomHeader = () => {
        dispatch({
            type: ACTIONS.DELETE,
            path: parentPath,
            index
        });
    };

    return (
        <Row>
            <Col>
                <Form.Label>Custom Header Name</Form.Label>
                <Form.Control
                    fieldname="name"
                    id={`customheadersName-${index}`}
                    onChange={handleCustomHeaderChange}
                    placeholder="Custom Header Name"
                    value={customHeader.name}
                />
                <ErrorComponent path={`${path}.name`} errors={errors} />
            </Col>
            <Col>
                <Form.Label>Custom Header Value</Form.Label>
                <Form.Control
                    fieldname="value"
                    id={`customheadersValue-${index}`}
                    onChange={handleCustomHeaderChange}
                    placeholder="Custom Header Value"
                    value={customHeader.value}
                />
                <ErrorComponent path={`${path}.value`} errors={errors} />
            </Col>
            <Col>
                <Form.Label>Delete</Form.Label>
                <br />
                <Button onClick={handleDeleteCustomHeader} variant="danger">-</Button>
            </Col>
        </Row>
    );
};

export default CustomHeader;
