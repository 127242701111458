import fetch from 'node-fetch';
import { DistributionStatus } from '../../types/misc';

const getDistributionStatus = async (token: string, distributionName: string): Promise<DistributionStatus> => {
    const response = await fetch(`${process.env.REACT_APP_ADN_MANAGEMENT_BASE_URL}/v1/distributions/${distributionName}/status`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json '
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to get Distribution ${distributionName}, got status code ${response.status}`);
    }

    const body = await response.json();
    return body.status;
};

export default getDistributionStatus;
