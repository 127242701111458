import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Origin from './Origin';
import { createEmptyOrigin } from '../../util/createEmptyObjects';
import Button from '../../components/Button';
import { useDistribution } from './DistributionProvider';

const Origins = ({ origins, path }) => {
    const { dispatch, ACTIONS } = useDistribution();

    const originsLength = origins.length;

    const defaultOriginExists = () => {
        return origins.some((origin) => {
            return origin.behaviors.some((behavior) => behavior.isDefaultBehavior);
        });
    };

    const addOrigin = () => {
        dispatch({
            type: ACTIONS.UPDATE,
            path,
            value: [...origins, createEmptyOrigin()]
        });
    };

    const buildOrigins = () => {
        return origins.map((origin, index) => {
            const id = origin.id || origin.uiId;

            return (
                <Origin
                    origin={origin}
                    assignedDefaultState={origin.behaviors.some((x) => x.isDefaultBehavior)}
                    path={`${path}[${index}]`}
                    parentPath={path}
                    displayDelete={originsLength > 1}
                    index={index}
                    key={id}
                    eventKeyId={id}
                    defaultOriginExists={defaultOriginExists}
                />
            );
        });
    };

    return (
        <>
            {buildOrigins()}
            <Row>
                <Col>
                    <Button onClick={addOrigin}>Add Origin</Button>
                </Col>
            </Row>
        </>
    );
};

export default Origins;
