import React from 'react';
import { Form, Col } from 'react-bootstrap';
import ErrorComponent from '../../util/Error';
import Button from '../../components/Button';
import { useDistribution } from './DistributionProvider';

const ForwardedHeader = ({ forwardedHeader, parentPath, path, index, errors }) => {
    const { dispatch, ACTIONS } = useDistribution();

    const handleForwardedHeaderNameChange = (event) => {
        dispatch({
            type: ACTIONS.UPDATE,
            value: event.target.value,
            path
        });
    };

    const handleDeleteForwardedHeader = () => {
        dispatch({
            type: ACTIONS.DELETE,
            path: parentPath,
            index
        });
    };

    return (
        <>
            <Form.Group as={Col}>
                <Form.Label>Forwarded Header Name</Form.Label>
                <Form.Control
                    id={`forwardedheaderName-${index}`}
                    onChange={handleForwardedHeaderNameChange}
                    placeholder="Forwarded Header Name"
                    value={forwardedHeader}
                />
                <ErrorComponent path={path} errors={errors} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Delete</Form.Label>
                <br />
                <Button onClick={handleDeleteForwardedHeader} variant="danger">-</Button>
            </Form.Group>
        </>
    );
};

export default ForwardedHeader;
